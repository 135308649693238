// @media screen and (max-width: 767px) {
//   .game-container{
//     margin: 20px 10%;
//   }
// }
// @media screen and (max-width: 565px) {
//   .game-container{
//     margin: 20px 10px;
//   }
// }
.game-container {
	display: flex;
}

.game-info {
	color: white;
}

.completed {
	text-decoration: none;
	color: white;
	font-weight: normal;
	min-width: 320px;
	text-align: center;
	font-size: 2.3em;
}

.availibility-timer {
	display: flex;
	text-align: center;
}

.game-dates {
	display: flex;
	flex-direction: column;
	text-align: left;
	font-weight: 300;
	color: #9f9f9f;
	min-width: 182px;
	font-size: 23px;
	// margin-right: 22px;
}

.game-dates p {
	margin: 0;
	width: 100%;
}

.game-release-date {
	float: right;
	font-weight: 400;
}

.game-links {
	display: flex;
}

.game-links .empty-icon:last-child,
.game-links .store-icon:last-child {
	margin-right: 0; 
}

@media (min-width: 320px) {
	/* smartphones, iPhone, portrait 480x320 phones */
	.game-container {
		flex-direction: column;
		align-items: center;
	}

	.game-info {
		font-size: 25px;
		
		h2 {
			margin: 0;
		}
	}

	.availibility-timer {
		margin-top: -15px;
	}

	.game-image img {
		height: 150px;
	}

	.game-progress {
		margin: 15px;
	}

	.empty-icon,
	.store-icon {
		margin: 25px 5px;
		margin-bottom: 20px;
	}
}
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width: 961px) {
	/* tablet, landscape iPad, lo-res laptops ands desktops */
	.game-container {
		flex-direction: row;
		align-items: center;
	}

	.game-image,
	.game-info,
	.availibility-timer,
	.game-links {
		padding: 10px;
	}

	.game-image {
		padding-left: 0;
	}

	.game-links {
		padding-right: 0;
	}

	.game-image img {
		height: 102px;
	}

	.game-info {
		color: white;
		text-align: left;
		min-width: 203px;
		max-width: 203px;

		h2 {
			font-size: 25px;
		}
	}

	.availibility-timer * {
		margin: 0 5px;
	}

	.game-progress {
		margin: 0 10px;
	}

	.empty-icon,
	.store-icon {
		margin: 0 25px;
	}

	.empty-icon {
		width: 73px;
	}
}
