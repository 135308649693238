.timer-box-number,
.timer-box-label {
	margin: 0;
	width: 90px;
}

.timer-box-number {
	color: white;
	font-weight: 600;
	font-size: 77px;
}

.timer-box-label {
	color: #A6A6A6;
	font-size: 20px;
	margin-top: -10px;
}